import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      console.log(formData);
      const response = await axios.post('https://api.wasi-pos.wasi.my.id/api/login', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response.data);

      if (response.data.status === false) {
        setErrors(response.data.data || {});
        Swal.fire({
          icon: 'error',
          title: 'Validation Error',
          text: response.data.message || 'Please check your input.',
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'User logged in successfully!',
        });
        localStorage.setItem('token', response.data.data.token);
        setErrors({});
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to log in user.',
      });
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-purple-900 p-4">
      <div className="flex bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-8xl m-4 h-full">
        {/* Right Section (Galaxy Background and Clouds) */}
        <div className="flex-1 bg-gradient-to-br from-purple-900 via-purple-700 to-purple-500 relative flex justify-center items-center p-10 order-2">
          <img src="/img/Wasi.png" alt="Wasi Illustration" className="z-10 max-w-full h-auto mb-20" />
          <div className="absolute bottom-0 left-0 w-72 h-24 bg-white rounded-full -mb-12 -ml-8"></div>
          <div className="absolute bottom-0 right-0 w-96 h-36 bg-white rounded-full -mb-16 -mr-10"></div>
        </div>
        
        {/* Left Section (Form) */}
        <div className="flex-1 p-12 flex flex-col justify-center order-1">
          <h2 className="text-4xl font-bold text-pink-500 mb-6">Selamat Datang Kembali!</h2>
          <p className="mb-6">Masuk untuk melanjutkan.</p>
          
          <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
            <div>
              <label className="block font-bold mb-1 text-gray-700">E-mail</label>
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full p-3 border rounded-lg focus:outline-none focus:border-pink-500"
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email.join(', ')}</p>}
            </div>
            
            <div>
              <label className="block font-bold mb-1 text-gray-700">Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                required
                className="w-full p-3 border rounded-lg focus:outline-none focus:border-pink-500"
              />
              {errors.password && <p className="text-red-500 text-sm">{errors.password.join(', ')}</p>}
            </div>
            
            <button type="submit" className="w-full py-3 bg-pink-500 text-white rounded-lg hover:bg-pink-600 transition duration-300">
              Masuk
            </button>
          </form>
          
          <div className="text-center mt-6">
            Belum punya akun? <a href="/register" className="text-pink-500 font-bold hover:underline">Daftar</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;